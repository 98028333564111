import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import SideImage from "../../assets/images/sideimage.png"
import Img from "gatsby-image"

const Toptag = () => {
  const {file} = useStaticQuery(graphql`
      {
          file(name: {eq: "sideimage"}) {
              childImageSharp {
                  fixed {
                      srcSet
                  }
              }
          }
      }

  `)
  console.log("homepage file",file)
  return (
    <CustomGrid style={{ marginTop: "50px" }} container spacing={1}>
      <Grid item lg={6} xs={12} sm={6}>
        <MainLine>
          <span className="mi">mi</span>{" "}
          <span className="aggrego">aggrego</span>{" "}
          <div className="ms-slider">
            <ul className="ms-slider__words">
              <li className="ms-slider__word">collects </li>
              <li className="ms-slider__word">publishes </li>
              <li className="ms-slider__word">promotes </li>
              <li className="ms-slider__word">collects </li>
            </ul>
          </div>
          <br />
          the finest pieces from the most renowned writers in the Italian food
          and wine sector
        </MainLine>
        <Para>
          Sign up to get the latest updates from the key opinion-makers and
          leading minds shaping Italian tastes
        </Para>
        <Link to="/dashboard">
          <CustomButton aria-label="Sign up" variant="outlined"> Sign Up</CustomButton>
        </Link>
      </Grid>
      <Grid item lg={4} xs={12} sm={6}>
        <Image>
          <Img style={{ width: "100%", height: "450px" }} fixed={file.childImageSharp.fixed} />
        </Image>
      </Grid>
    </CustomGrid>
  )
}

export default Toptag

const MainLine = styled.h1`
  font-family: "GT-Walsheim-Bold", sans-serif;
  font-size: 36px;
  line-height: 2.3rem;

  .mi {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-weight: 300;
    font-size: 36px;
    color: black;
    margin-right: -7px;
  }
  .aggrego {
    color: black;
  }
`

const Para = styled.p`
  font-family: "GT-Walsheim-Thin", sans-serif;
  font-weight: 200;
  font-size: 28px;
  line-height: 1.9rem;
`

const Image = styled.div`
  Img {
    min-width: 580px;
    margin-top: -15%;
  }
 
  @media (max-width: 794px) {
    Img {
      /* min-width: 400px; */
      display: none;
    }
  }
`

const CustomButton = styled(Button)`
  background-color: #232323;
  font-family: "GT-Walsheim-Light", sans-serif;
  font-size: 1rem;
  color: white;
 
  text-decoration: none;
  :hover {
    background-color: #2323233d;
    color: #232323;
  }
`

const CustomGrid = styled(Grid)`
  a {
    text-decoration: none;
  }
`
