import { graphql } from "gatsby"
import React from "react"
import TopTag from "../components/homepage/topTag"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Line } from "../components/styles/styles"
import ContentSectionMarkdown from "../components/homepage/ContentSection-md"

const element = { index: 0, value: "foo", fu: ()=>"tee" };
const index1 = element.value.d ?? "test1"; //  0 :D
const index2 = element.fua?.() ?? "NON  check"; // -1 :(

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="Home" />
      <TopTag />
      <Line />
      <ContentSectionMarkdown
        title="Trending Stories"
        data={props.data.Trending_md}
        lg={6}
        xs={12}
        sm={6}
        height="280px"
        linkToPage="sections/TrendingTopics"
        posts_on_homepage={2}
        style={{ image: "cover", height: "280px" }}
      />
      <Line />
      <ContentSectionMarkdown
        title="Food Stories"
        data={props.data.FoodWinePairing_md}
        lg={4}
        xs={12}
        sm={6}
        height="240px"
        style={{ image: "cover", height: "240px" }}
        linkToPage="sections/FoodWinePairing"
        posts_on_homepage={3}
      />
      {/*<Line />*/}

      {/*<ContentSection*/}
      {/*  title=" Wine Art"*/}
      {/*  data={props.data.WineArt}*/}
      {/*  lg={3}*/}
      {/*  height="200px"*/}
      {/*  xs={12}*/}
      {/*  sm={6}*/}
      {/*  linkToPage="sections/WineArt"*/}
      {/*  posts_on_homepage={4}*/}
      {/*/>*/}
      <Line />
      <ContentSectionMarkdown
        title="Discover Italy"
        data={props.data.WineCulture_md}
        lg={3}
        xs={12}
        sm={6}
        height="180px"
        linkToPage="sections/WineCulture"
        posts_on_homepage={4}
        style={{ image: "cover", height: "180px" }}
      />
      <Line />
      <ContentSectionMarkdown
        title="Blogs & Opinion"
        data={props.data.Blogs_md}
        lg={4}
        xs={12}
        sm={6}
        height="180px"
        linkToPage="sections/Blogs"
        posts_on_homepage={3}
        style={{ image: "cover", height: "180px" }}
      />
      <Line />
      <ContentSectionMarkdown
        title="Top 10 Lists"
        data={props.data.Top10Lists_md}
        lg={3}
        xs={12}
        sm={6}
        height="180px"
        linkToPage="sections/Top10Lists"
        posts_on_homepage={4}
        style={{ image: "cover", height: "180px" }}
      />
      {/*<Line />*/}
      {/*<ContentSection*/}
      {/*  title="Book Reviews"*/}
      {/*  data={props.data.BookReviews}*/}
      {/*  lg={3}*/}
      {/*  xs={12}*/}
      {/*  sm={6}*/}
      {/*  height="200px"*/}
      {/*  linkToPage="sections/BookReviews"*/}
      {/*  posts_on_homepage={4}*/}
      {/*/>*/}
      {/*<Line />*/}
      {/*<SmallSection*/}
      {/*  title="Wine & Social Media | Influencer Series"*/}
      {/*  data={props.data.WineSocial}*/}
      {/*  showMedia={false}*/}
      {/*  lg={3}*/}
      {/*  xs={12}*/}
      {/*  sm={12}*/}

      {/*/>*/}
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  {
    Trending_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Trending Stories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }

    FoodWinePairing_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Food & Wine Pairing" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
    WineArt_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Wine Art" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
    WineCulture_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Wine Culture" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
    Blogs_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Blogs & Opinion" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
    Top10Lists_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Top 10 Lists" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
